import { gsap } from "gsap";

export function tabs(selector) {
    var tabs = $$(selector);
    tabs.forEach(function (tab) {
        var tabTriggers = $$('.tab-nav', tab);
        tabTriggers.forEach(function (trigger) {
            $.bind(trigger, {
                'click': function (event) {
                    event.preventDefault();
                    $('.tab-nav.active').classList.remove('active');
                    $('.tab-content.active').classList.remove('active');
                    trigger.classList.add('active');
                    var contentAnchor = trigger.getAttribute('href');
                    $(contentAnchor + '.tab-content').classList.add('active');
                }
            });
        });
    });
}



export function accordion(selector) {
    var accordions = $$(selector);
    accordions.forEach(function (accordion) {
        var togglers = $$('[data-accordion-toggle]', accordion);

        togglers.forEach(function (toggler) {
            $.bind(toggler, {
                'click': function(event) {
                    event.preventDefault();
                    var icon = $('i', this);
                    if (icon !== null) {
                        this.classList.toggle('bg-blue-darkest');
                        this.classList.toggle('bg-blue-lightest');
                        icon.classList.toggle('fa-minus');
                        icon.classList.toggle('fa-plus');
                    }
                    var panel = $(this.getAttribute('href'));
                    var params = {
                        duration: 0.3,
                        ease: 'none',
                        height: panel.scrollHeight
                    };
                    if (panel.style.height !== '0px') {
                        params.height = 0;
                    }
                    gsap.to(panel, params);
                }
            });
        });

    });
}

export function closeModal(m) {
    const overlay = m.parentNode;
    const tl = gsap.timeline({onComplete: function () {
            tl.kill();
        }
    });
    tl.to(m, {
        duration: .5, ease: 'power2.in', opacity: 0, yPercent: -100,
        onComplete: function() {
            document.body.appendChild(m);
            m.style = '';
            overlay.remove();
            document.body.classList.remove('overflow-hidden');
            tl.kill();
        }
    }).to(overlay, {
        duration: .5, ease: 'linear', backgroundColor: 'transparent'
    }, '<');

}

export function modal(selector) {
    function openModal(m) {
        document.body.classList.add('overflow-hidden');
        const tl = gsap.timeline({onComplete: function () {
                tl.kill();
            }
        });
        tl.fromTo(m,
            {opacity: 0, yPercent: -100},
            {duration: .5, ease: 'power2.out', opacity: 1, yPercent: 0}
        ).from(m.parentNode, {
            duration: .5, ease: 'linear', backgroundColor: 'transparent'
        }, '<');
    }
    const m = $(selector);
    m._.bind({
        'click': function(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    });
    $('.btn-modal-close', m)._.bind({
        'click': function(event) {
            event.preventDefault();
            closeModal(m);
        }
    });
    var element = $.create('div', {
        className: 'modal-overlay'
    });
    element.appendChild(m);
    document.body.append(element);
    openModal(m);
    element._.bind({
        'click': function() {
            closeModal(m);
        }
    });

    // $$(selector).forEach(function(m) {
    //     m._.bind({
    //         'click': function(event) {
    //             event.preventDefault();
    //             event.stopImmediatePropagation();
    //         }
    //     });
    //     $('.btn-modal-close', m)._.bind({
    //         'click': function(event) {
    //             event.preventDefault();
    //             closeModal(m);
    //         }
    //     });
    //     const modalID = m.getAttribute('id');
    //     $('[data-toggle-modal="'+modalID+'"]')._.bind({
    //         'click': function(event) {
    //             event.preventDefault();
    //             var element = $.create('div', {
    //                 className: 'modal-overlay'
    //             });
    //             element.appendChild(m);
    //             document.body.append(element);
    //             openModal(m);
    //             element._.bind({
    //                 'click': function() {
    //                     closeModal(m);
    //                 }
    //             })
    //         }
    //     });
    // });
}