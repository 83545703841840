require('blissfuljs');
require('fslightbox');

import { gsap, Circ, Bounce, Back, Power4, Linear } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { TextPlugin } from "gsap/dist/TextPlugin";
gsap.registerPlugin(ScrollTrigger, TextPlugin);

import { tabs, accordion, modal, closeModal } from "./lib";
tabs('.tabs');
accordion('#accordion, #accordion-qa, #accordion-read-more');
window.modalActivate = modal;

import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({
    elements_selector: '.lazy',
    use_native: true
});
const lazyBackground = new LazyLoad({
    elements_selector: '.lazy-bg',
});

// import Masonry from 'masonry-layout';
// var masonryParams = {
//     percentPosition: true,
//     itemSelector: '.masonry-item',
//     gutter: window.innerWidth < 1440 ? 0 : 120
// };
// if (window.innerWidth >= 1440) {
//     masonryParams.columnWidth = 320;
//     masonryParams.percentPosition = false;
// }
// var masonry = new Masonry('.masonry', masonryParams);

import Inputmask from "inputmask";
Inputmask("+7 (999) 999-99-99").mask('input[type="tel"]');

import Swiper from 'swiper';
import SwiperCore, { Navigation } from 'swiper/core';
SwiperCore.use([Navigation]);
import 'swiper/swiper.min.css';

window.schoolPhotoCarousel = new Swiper('#school-photo-carousel', {
    slidesPerView: 1,
    loop: true,
    speed: 1500,

    breakpoints: {
        1000: {
            slidesPerView: 2,
            spaceBetween: 60
        },
        1200: {
            slidesPerView: 2,
            spaceBetween: 40
        },
        1440: {
            slidesPerView: 3,
            spaceBetween: 40
        }
    },

    // Navigation arrows
    navigation: {
        nextEl: '#school-photo-carousel ~ .carousel-button-right',
        prevEl: '#school-photo-carousel ~ .carousel-button-left',
    },
});

const newsSwiper = new Swiper('#news-carousel', {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 10,
    speed: 1500,

    breakpoints: {
        1000: {
            slidesPerView: 2,
            spaceBetween: 64
        },
        1200: {
            slidesPerView: 2,
            spaceBetween: 32,
            simulateTouch: false
        },
        1440: {
            slidesPerView: 3,
            spaceBetween: 24,
            simulateTouch: false
        }
    },

    // Navigation arrows
    navigation: {
        nextEl: '#news-carousel ~ .carousel-button-right',
        prevEl: '#news-carousel ~ .carousel-button-left',
    },
});

const partnersSwiper = new Swiper('#partners-carousel', {
    slidesPerView: 1,
    spaceBetween: 40,
    loop: true,
    speed: 1500,

    breakpoints: {
        576: {
            slidesPerView: 2,
            spaceBetween: 40,
            slidesPerGroup: 2
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 40,
            slidesPerGroup: 2
        },
        1440: {
            slidesPerView: 2,
            spaceBetween: 40,
            slidesPerGroup: 2,
            simulateTouch: false
        }
    },

    // Navigation arrows
    navigation: {
        nextEl: '#partners-carousel ~ .carousel-button-right',
        prevEl: '#partners-carousel ~ .carousel-button-left',
    },
});

window.childPhotoSwiperInit = function() {
    if ($('#child-photos') !== null) {
        const childSwiper = new Swiper('#child-photos', {
            slidesPerView: 3,
            spaceBetween: 16,
            loop: true,
            speed: 1500
        });
        childSwiper.update();
    }
}


var master = gsap.timeline();
var priceTimeline = gsap.timeline({
    scrollTrigger: {
        trigger: "#price-timeline",
        // start: "bottom bottom"
    }
});

function preloader() {
    var tl = gsap.timeline();

    tl
        .to(
        '#preview-ball',
        {duration: 6, ease: Circ.easeOut, opacity: 1, x: window.innerWidth + 250, y: - (window.innerHeight + 250)}
    )
        .fromTo(
        '#preview-2',
        {opacity: 0, yPercent: -150},
        {duration: 2, ease: Back.easeOut, opacity: 1, yPercent: 0},
        1.5
    ).fromTo(
        '#preview-1',
        {opacity: 0, xPercent: -200},
        {duration: 2, ease: Bounce.easeOut, opacity: 1, xPercent: 0},
        '-=3'
    ).fromTo(
        '#preview-3',
        {opacity: 0, xPercent: 250},
        {duration: 2, ease: Bounce.easeOut, opacity: 1, xPercent: 0},
        '<'
    ).to(
        '#preview-2', {duration: 0.15, scale: 0.8, rotation: -8}, '-=1'
    ).to(
        '#preview-2',{duration: 0.1, scale: 1.2, rotation: 0}
    ).to(
        '#preview-2',{duration: 0.1, rotation: 4, repeat: 8, yoyo: true}
    ).to(
        '#preview-2', {duration: 0.15, scale: 1, rotation: 0}
    );

    if (window.innerWidth >= 1200) {
        tl.to(
            '#preview-1', {duration: 1, xPercent: -200, ease: Back.easeIn}, '+=1'
        ).to(
            '#preview-2', {duration: 1, yPercent: -150, ease: Back.easeIn}, '<'
        ).to(
            '#preview-3', {duration: 1, xPercent: 250, ease: Back.easeIn}, '<'
        ).to(
            '#preview-screen', {duration: 1, backgroundColor: 'transparent',
                onComplete: () => {
                    document.body.classList.remove('1200:overflow-hidden');
                    document.getElementById('preview-screen').remove();
                }
            }, '<',
        ).fromTo('#field-logo',
            {opacity: 0, scale: 2},
            {duration: 2, opacity: 1, scale: 2},
        );
    }


    return tl;
}

function field() {
    var tl = gsap.timeline();

    var cw = 144;
    var cwh = cw / 2;
    var middleY = window.innerHeight / 2 - 1.5 * cwh;

    tl.fromTo('#person-1',
        {opacity: 0, y: middleY},
        {opacity: 1, x: window.innerWidth / 5, y: window.innerHeight / 8, duration: 2, ease: Circ.easeOut},
        Math.random() * 1.25
    ).fromTo('#person-2',
        {opacity: 0, y: middleY},
        {opacity: 1, x: window.innerWidth / 3, y: window.innerHeight / 4, duration: 2, ease: Circ.easeOut},
        Math.random() * 1.25
    ).fromTo('#person-3',
        {opacity: 0, y: middleY},
        {opacity: 1, x: window.innerWidth / 3.5, y: window.innerHeight / 1.75, duration: 2, ease: Circ.easeOut},
        Math.random() * 1.25
    ).fromTo('#person-4',
        {opacity: 0, y: middleY},
        {opacity: 1, x: window.innerWidth / 5.5, y: window.innerHeight / 1.35, duration: 2, ease: Circ.easeOut},
        Math.random() * 1.25
    ).fromTo('#person-5',
        {opacity: 0, y: middleY},
        {opacity: 1, x: cwh, y: middleY, duration: 2, ease: Circ.easeOut},
        Math.random() * 1.25
    )


    .fromTo('#person-6',
        {opacity: 0, y: middleY},
        {opacity: 1, x: -window.innerWidth / 6, y: window.innerHeight / 8, duration: 2, ease: Circ.easeOut},
        Math.random() * 1.25
    ).fromTo('#person-7',
        {opacity: 0, y: middleY},
        {opacity: 1, x: -window.innerWidth / 3, y: window.innerHeight / 4, duration: 2, ease: Circ.easeOut},
        Math.random() * 1.25
    ).fromTo('#person-8',
        {opacity: 0, y: middleY},
        {opacity: 1, x: -window.innerWidth / 2.75, y: window.innerHeight / 1.85, duration: 2, ease: Circ.easeOut},
        Math.random() * 1.25
    ).fromTo('#person-9',
        {opacity: 0, y: middleY},
        {opacity: 1, x: -window.innerWidth / 5, y: window.innerHeight / 1.5, duration: 2, ease: Circ.easeOut},
        Math.random() * 1.25
    ).fromTo('#person-10',
        {opacity: 0, y: middleY},
        {opacity: 1, x: -cwh, y: middleY, duration: 2, ease: Circ.easeOut},
        Math.random() * 1.25
    )


    .fromTo('#field-logo',
        {scale: 2, zIndex: 9},
        {duration: 2, ease: Back.easeOut, scale: 1, marginTop: 0, zIndex: 5},
        '<'
    ).fromTo('#field-button',
        {opacity: 0},
        {duration: 2, ease: Back.easeOut, opacity: 1, y: -50},
        '<'
    )

    return tl;
}


function finalPrice() {
    var tl = gsap.timeline();

    tl.fromTo('#price-end',
        {opacity: 0},
        {duration: 2, opacity: 1}
    ).fromTo('#price-slutsky',
        {width: 0, opacity: 0},
        {duration: 2, ease: Power4.easeOut, opacity: 1, width: '40%'}
    ).fromTo('#price-people',
        {opacity: 0, width: 0},
        {duration: 2, ease: Power4.easeOut, opacity: 1, width: '80%'},
        '<'
    ).fromTo('#price-berezutsky',
        {opacity: 0, width: 0},
        {duration: 2, ease: Power4.easeOut, opacity: 1, width: '55%'},
        '<'
    )

    return tl;
}


master.add(preloader());
if (window.innerWidth >= 1200) {
    master.add(field());
}


priceTimeline.add(finalPrice());



setInterval(function() {
    const randomPosition = Math.floor(Math.random() * 10) + 1;
    const child = $('#person-'+randomPosition);
    const bubble = $('.bubble', child);
    if (bubble !== null) {
        const bubbleText = bubble.getAttribute('data-bubble-text');
        const tl = gsap.timeline({
            onComplete: function () {
                tl.kill();
            }
        })
        tl.fromTo(bubble,
            {opacity: 0, width: 0, fontSize: 0},
            {duration: 0.5, ease: Circ.easeInOut, opacity: 1, width: 200, fontSize: 16}
        ).to(bubble,
            {duration: 3, ease: Linear.easeNone, text: {value: bubbleText}}
        ).to(bubble,
            {duration: 0.1, ease: Linear.easeNone, text: {value: ''}},
            '+=5'
        )
        .to(bubble,
            {duration: 0.5, ease: Circ.easeInOut, opacity: 0, width: 0, fontSize: 0},
            '<'
        );
    }
}, 15000);



$$('input.validate').forEach(function(input) {
    input._.bind({
        "focus": function(event) {
            var inputBlock = input.parentNode;
            inputBlock.classList.remove('correct');
            inputBlock.classList.remove('error');
            input.classList.remove('correct');
            input.classList.remove('error');
        },
        "blur focusOut": function(event) {
            var inputBlock = input.parentNode;
            var type = input.getAttribute('type');
            var cls = 'correct';
            if (type === 'text') {
                cls = input.value.length > 0 ? 'correct' : 'error';
            } else if (type === 'tel') {
                cls = input.inputmask.isComplete() ? 'correct' : 'error';
            } else if (type === 'email') {
                cls = (input.value.length > 0 && input.validity.valid) ? 'correct' : 'error';
            }
            inputBlock.classList.add(cls);
            input.classList.add(cls);
        }
    })
});


$('#price-pay')._.bind({
    'focus': function() {
        $('#price-pay').parentElement.classList.remove('error');
        var check = $('input[type="radio"]:checked')
        if (check) {
            check.checked = false;
        }
    },
    'input': function () {
        $('#price-pay').parentElement.classList.remove('error');
        $('#product-pay').value = '';
    }
});

$$('input[type="radio"]').forEach(function(radio) {
    radio._.bind({
        'change': function() {
            const priceElement = $('#price-pay');
            priceElement.parentElement.classList.remove('error');
            priceElement.value = '';
            $('#product-pay').value = '';
        }
    });
})

$$('.btn-take-part').forEach(function (element) {
    element._.bind({
        'click': function(event) {
            event.preventDefault();
            $('#form-payment').scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    });
});